import ReactDOM from 'react-dom';
import * as React from 'react';
import { store } from 'Shared/ifixit_store';
import { Provider } from 'react-redux';
import { withErrorInfo } from 'Shared/logging';
import { initializeReactQuery } from 'Shared/react-query-initialize';

const getPlaceholders = name => {
   return document.querySelectorAll(`.react-component[data-name="${name}"]`);
};

/**
 * This will mount React component `Component` in placeholder divs that have
 * a data-name of `name`.
 *
 * This is used by the ReactComponent.php class to indicate mount points.
 */
const initializeComponent = (name, Component, defaultProps) => {
   onDomReady(function () {
      let placeholders = getPlaceholders(name);

      Array.convert(placeholders).forEach(placeholder => {
         // eslint-disable-next-line no-console
         console.time(name);
         let props = {
            ...defaultProps,
            ...JSON.parse(placeholder.getAttribute('data-props')),
         };

         withErrorInfo(() => {
            ReactDOM.render(
               initializeReactQuery(
                  <Provider store={store}>
                     <Component {...props} />
                  </Provider>
               ),
               placeholder
            );
         }, ['Error occurred while rendering', Component, 'with props', props]);
         // eslint-disable-next-line no-console
         console.timeEnd(name);
      });
   });
};

/** Reexport our Redux store, because some components expect it defined here */
export { store };

export default initializeComponent;
